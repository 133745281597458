import {Routes, Route, Link} from "react-router-dom";


import Dashboard from "./pages/Dashboard";
import Upload from "./pages/Upload";

function App() {


    return (
        <Routes>

            <Route path="/" element={<Dashboard/>}/>
            <Route path="/upload" element={<Upload/>}/>

        </Routes>
    );
}

export default App;
