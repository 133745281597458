import {FC} from "react";
// @ts-ignore
import ReactS3Uploader from 'react-s3-uploader-multipart'
import axios from 'axios'
const Upload:FC = () => {


    const callback = (ev: any) => {
      console.log({ev})
    }

    const getSignedUrl = (file: { name: any; type: any; }, callback: (arg0: Response) => void) => {
        const params = {
            fileName: file.name,
            contentType: file.type
        };

        axios.get('https://gaq2smu6d6imrvg2ajxyajgzje0rgpbi.lambda-url.us-west-2.on.aws/', {
            params,
        }).then(res => {
            callback(res.data);
        })
    }

return (
    <section className={"py-xl-1"}>
        <div className="container container-sm">
            <div className="mb-3">
                <label htmlFor="stockNumber" className="form-label">Stock #</label>
                <input type="text" className="form-control" id="stockNumber"
                       placeholder="PS33244"/>
            </div>

            <div>
                <ReactS3Uploader
                    evaporateOptions={{
                        aws_key: 'AKIAY4ETHUBZNC4SGY2K',
                        bucket: 'cdn.virticle.app'
                    }}
                    getSignedUrl={getSignedUrl}
                    accept="video/*"
                    onProgress={callback}
                    onError={callback}
                    onFinish={callback}
                    uploadRequestHeaders={{
                        'x-amz-acl': 'public-read'
                    }}
                    contentDisposition="auto"
                    autoUpload={true}
                />
            </div>
        </div>
    </section>

)
}


export default Upload