import {createRef, FC, LegacyRef, useEffect, useRef} from "react";

// @ts-ignore
import * as PANOLENS from "panolens";
import * as THREE from "three";


const InsideViewer: FC = () => {


    useEffect(() => {
        // @ts-ignore
        document.querySelector("#coucou").innerHTML = ''

        const panorama = new PANOLENS.ImagePanorama("https://ik.imagekit.io/nginr/virticle/tr:q-100,w-1800,f-auto,pr-true/PIS110110/interior/GS.JPG");
        const viewer = new PANOLENS.Viewer({
            controlBar: false,
            container: document.querySelector("#coucou"),
            dampingFactor: .1,
            output: 'overlay',
        });


        const infospot = new PANOLENS.Infospot(300, 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5OCIgaGVpZ2h0PSI5OCI+PGcgZGF0YS1uYW1lPSJMYXllciAyIj48ZyBkYXRhLW5hbWU9IkxheWVyIDEiPjxjaXJjbGUgY3g9IjQ5IiBjeT0iNDkiIHI9IjQ5IiBzdHlsZT0iZmlsbDojZmZmIi8+PGNpcmNsZSBjeD0iNDkiIGN5PSI0OSIgcj0iNDAiIHN0eWxlPSJmaWxsOiM1MTgzMjQiLz48cGF0aCBkPSJNNjEuNzEgNDYuNjJINTEuMzhWMzYuMjlhMi4zOCAyLjM4IDAgMSAwLTQuNzYgMHYxMC4zM0gzNi4yOWEyLjM4IDIuMzggMCAxIDAgMCA0Ljc2aDEwLjMzdjEwLjMzYTIuMzggMi4zOCAwIDEgMCA0Ljc2IDBWNTEuMzhoMTAuMzNhMi4zOCAyLjM4IDAgMSAwIDAtNC43NloiIHN0eWxlPSJmaWxsOiNmZmYiLz48L2c+PC9nPjwvc3ZnPg==');
        infospot.position.set(5000.00, 865.23, 0.49);
        infospot.addHoverText('Apple CarPlay')


        const infospot2 = new PANOLENS.Infospot(300, 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5OCIgaGVpZ2h0PSI5OCI+PGcgZGF0YS1uYW1lPSJMYXllciAyIj48ZyBkYXRhLW5hbWU9IkxheWVyIDEiPjxjaXJjbGUgY3g9IjQ5IiBjeT0iNDkiIHI9IjQ5IiBzdHlsZT0iZmlsbDojZmZmIi8+PGNpcmNsZSBjeD0iNDkiIGN5PSI0OSIgcj0iNDAiIHN0eWxlPSJmaWxsOiM1MTgzMjQiLz48cGF0aCBkPSJNNjEuNzEgNDYuNjJINTEuMzhWMzYuMjlhMi4zOCAyLjM4IDAgMSAwLTQuNzYgMHYxMC4zM0gzNi4yOWEyLjM4IDIuMzggMCAxIDAgMCA0Ljc2aDEwLjMzdjEwLjMzYTIuMzggMi4zOCAwIDEgMCA0Ljc2IDBWNTEuMzhoMTAuMzNhMi4zOCAyLjM4IDAgMSAwIDAtNC43NloiIHN0eWxlPSJmaWxsOiNmZmYiLz48L2c+PC9nPjwvc3ZnPg==');
        infospot2.position.set(-2347.49, 826.37, -4328.99);
        infospot2.addHoverText('Leather Seats')

        const infospot3 = new PANOLENS.Infospot(300, 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5OCIgaGVpZ2h0PSI5OCI+PGcgZGF0YS1uYW1lPSJMYXllciAyIj48ZyBkYXRhLW5hbWU9IkxheWVyIDEiPjxjaXJjbGUgY3g9IjQ5IiBjeT0iNDkiIHI9IjQ5IiBzdHlsZT0iZmlsbDojZmZmIi8+PGNpcmNsZSBjeD0iNDkiIGN5PSI0OSIgcj0iNDAiIHN0eWxlPSJmaWxsOiM1MTgzMjQiLz48cGF0aCBkPSJNNjEuNzEgNDYuNjJINTEuMzhWMzYuMjlhMi4zOCAyLjM4IDAgMSAwLTQuNzYgMHYxMC4zM0gzNi4yOWEyLjM4IDIuMzggMCAxIDAgMCA0Ljc2aDEwLjMzdjEwLjMzYTIuMzggMi4zOCAwIDEgMCA0Ljc2IDBWNTEuMzhoMTAuMzNhMi4zOCAyLjM4IDAgMSAwIDAtNC43NloiIHN0eWxlPSJmaWxsOiNmZmYiLz48L2c+PC9nPjwvc3ZnPg==');
        infospot3.position.set(3858.78, 68.27, -3164.74);
        infospot3.addHoverText('Limited Edition')

        panorama.add(infospot)
        panorama.add(infospot2)
        panorama.add(infospot3)


        viewer.add(panorama);
        viewer.renderer.sortObjects = true;
        const control = viewer.getControl();
        control.panLeft(-36)
        control.panLeft(-36)
        control.panLeft(-36)

        const container: HTMLDivElement = viewer.getContainer()

        Array.from([infospot, infospot2, infospot3]).map(is => {
            is.addEventListener('hoverenter', function () {
                if (!container.classList.contains('infoHover')) {
                    container.classList.add('infoHover')
                }
            });
        })

        Array.from([infospot, infospot2, infospot3]).map(is => {
            is.addEventListener('hoverleave', function () {
                if (container.classList.contains('infoHover')) {
                    container.classList.remove('infoHover')
                }
            });
        })


        return () => {
            if (viewer) {
                viewer.destroy()
            }
        }

    }, []); // will only be called when the src prop gets updated

    return (
        <div id="coucou" style={{height: '550px', width: '100%'}} className={'position-relative interior-container'}/>
    )
}

export default InsideViewer