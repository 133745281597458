import React, {useState} from 'react';
import Navbar from "../components/Navbar";
import CarViewer from "../components/CarViewer";
import InsideViewer from "../components/InsideViewer";
import {TbSteeringWheel} from "react-icons/tb";
import {IoCarSportOutline} from "react-icons/io5";
import styled from "styled-components";


const Container = styled.div`
  position: relative;

  button {
    position: absolute;
    top: 30px;
    left: 30px;
  }
`

function Dashboard() {

    const [inside, setInside] = useState<boolean>(false)

    return (
        <div className="App">
            <Navbar/>
            <div className={"container"}>
                <img className={"w-100"} src="/mocks/toolbar.png" alt=""/>
            </div>
            <section className={"muted pb-0"}>
                <div className={"container"}>
                    <img className={"w-100"} src="/mocks/top-a.png" alt=""/>
                </div>
            </section>

            <section className={"muted"}>
                <div className="container container-sm">
                    <div className="row">
                        <div className="col-10">
                            <Container>
                                {!inside && <CarViewer/>}
                                {inside && <InsideViewer/>}
                                <button className={"btn btn-primary d-flex align-items-center"} onClick={() => setInside(!inside)}>
                                    {inside ? <IoCarSportOutline/> :
                                        <TbSteeringWheel/>} <span className={"ms-2"}>View {inside ? 'Outside' : 'Inside'}</span>
                                </button>
                            </Container>
                        </div>
                        <div className="col-2">
                            <img src="/mocks/viewer-side.png" style={{height: 550, objectFit: 'contain'}}
                                 className={"w-100"} alt=""/>
                        </div>
                    </div>
                </div>
            </section>


            <div className={""}>
                <img className={"w-100"} src="/mocks/full.png" alt=""/>
            </div>
        </div>
    );
}

export default Dashboard;
