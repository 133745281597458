const Navbar =() => {
    return (
        <header className="header scrollUp" role="banner">
            <div className="container-fluid">
                <div className="row header_primary">
                    <div className="col-lg-5 hamburger_menu">
                        <div className="main_navigation">
                            <div className="menu_header d-flex d-lg-none align-items-center justify-content-between">
                                <a className="menu-logo" role="link" href="/" target="_self">
                                    <img
                                        src="/-/media/Project/Sonic/EchoPark/Header/logos-echo-park-green-white.svg?iar=0&hash=BBDEA56065DCBF4AB1A358A6B3ECC44F"
                                        alt="EchoPark"/> </a>
                                <span className="menu_close"/>
                            </div>
                            <nav role="navigation">
                                <ul>
                                    <li>
                                        <a href="/used-cars" role="link">Shop cars</a>
                                    </li>
                                    <li>
                                        <a href="/sell-my-car" role="link">Sell/Trade</a>
                                    </li>
                                    <li>
                                        <a href="/financing" role="link">Get approved</a>
                                    </li>
                                    <li className="more_links">
                                        <a className="d-none d-lg-block" role="button"
                                           tabIndex={0}>More</a>
                                    </li>
                                </ul>
                            </nav>
                            <div className="menu_footer d-block d-lg-none">
                                <a className="btn btn-primary target_links" rel="show-signIn" role="link" href="#">Sign
                                    in</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-5 col-lg-2 header-logos-column">
                        <div className="toggle_menu d-block d-lg-none"/>
                        <div className="header_logo">
                            <a className="reguler-logo" role="link" href="/" target="_self">
                                <img
                                    src="/logo.png"
                                    height={36}/>
                            </a>
                        </div>
                    </div>
                    <div className="col-7 col-lg-5 text-end">
                        <div className="header_right">
                            <div className="zipLocation text-right">
                                <div className="d-flex flex-column">
                                    <span className="spShoppingLocationsLabel d-none d-lg-block"
                                          id="spShoppingLocationsLabel">Picking up closest to </span>
                                    <a id="ancHeaderZipcode" className="zipLocation--code" role="link"
                                       href="#">80238</a>
                                </div>
                                <a className="zipLocation--icon" role="button"  tabIndex={0}>Zip
                                    Location</a>
                            </div>
                            <div className="my_favorates">
                                <a href="/favorites" role="link">My Favorites</a>
                            </div>
                            <div className="user_profile">
                                <a className="guest-user target_links" rel="show-signIn" role="link" href="#">
                                    Sign in
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Search Bar */}
            {/*<div className="search-bar">*/}
            {/*    <div className="container-fluid">*/}
            {/*        <form  role="form" autoComplete="off">*/}
            {/*            <div className="searchBarMain">*/}
            {/*                <div className="searchBarIn">*/}
            {/*                    <label className="d-none" htmlFor="searchbar"*/}
            {/*                           aria-label="Search make, model, or feature">*/}
            {/*                        Search make, model, or feature*/}
            {/*                    </label>*/}
            {/*                    <input type="search" className="search-bar-input searchInput" id="searchbar"*/}
            {/*                           placeholder="Search make, model, or feature"  aria-label="search"*/}
            {/*                           autoComplete="off"/>*/}
            {/*                    <button type="button" role="button" id="btnSearchIcon" className="btn searchBtn"*/}
            {/*                            data-href>*/}
            {/*                        <span>&nbsp;</span>Search*/}
            {/*                    </button>*/}
            {/*                    <button type="button" role="button" className="btn clearSearch" id="clearSearch"*/}
            {/*                            aria-label="clear">*/}
            {/*                        <span>&nbsp;</span>*/}
            {/*                    </button>*/}
            {/*                </div>*/}
            {/*                <button className="searchCancelMob" id="searchCancelMob" role="button" type="button">*/}
            {/*                    Cancel*/}
            {/*                </button>*/}
            {/*            </div>*/}
            {/*        </form>*/}
            {/*        <ul className="search-suggestions" role="presentation"/>*/}
            {/*    </div>*/}
            {/*    <div className="searchAutoSaggetion--container">*/}
            {/*        <div className="container-fluid">*/}
            {/*            <div className="searchAutoSaggetion">*/}
            {/*                <div className="autocomplete-suggestions" style={{display: 'none', zIndex: 9999}}/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </header>
    )
}

export default Navbar